import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bad-password',
  templateUrl: './bad-password.component.html',
  styleUrls: ['./bad-password.component.css']
})
export class BadPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
