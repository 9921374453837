import { Component } from '@angular/core';

@Component({
  selector: 'app-letsconnect',
  templateUrl: './letsconnect.component.html',
  styleUrls: ['./letsconnect.component.css']
})
export class LetsconnectComponent{

  constructor() { }

}
